import React from 'react';
import emailjs from 'emailjs-com';
import Button from './ui/btn';

export default function Contact() {
    const sendEmail = (e) => {
        e.preventDefault();

        // Initialize Email.js
        emailjs.init('nnnqwX5JYXy3e6Oi0');

        // Prepare the email template parameters
        const templateParams = {
            message: e.target.message.value,
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value
        };

        // Send the email
        emailjs.send('service_8zjgmdx', 'template_64pcc4g', templateParams)
            .then((response) => {
                console.log('Email sent successfully:', response);
                alert("Request Sent")
            })
            .catch((error) => {
                console.error('Email sending failed:', error);
            });
    };

    return (
        <div id="contact" className="flex flex-col justify-center items-center px-6 md:px-10 lg:px-20 mt-32 mb-20">
            <h2 className="font-hb text-2xl md:text-4xl text-center">WANT TO FEEL THE<br /><span className="text-[#D8B25D]">LUXURIOUS JOY?</span></h2>
            <div className="grid md:grid-cols-2 gap-4 w-full mt-10">
                <form onSubmit={sendEmail} className="grid md:grid-cols-2 gap-4 md:w-11/12">
                    <input type="text" name="name" placeholder="ADD YOUR NAME" className="border-b-2 border-[#131313] bg-transparent placeholder:text-[#131313] px-0 py-3 md:my-6 md:w-11/12" />
                    <input type="text" name="email" placeholder="ADD YOUR E-MAIL" className="border-b-2 border-[#131313] bg-transparent placeholder:text-[#131313] px-0 py-3 md:my-6 md:w-11/12" />
                    <input type="text" name="phone" placeholder="ADD YOUR PHONE NO" className="border-b-2 border-[#131313] bg-transparent placeholder:text-[#131313] px-0 py-3 md:my-6 md:w-11/12" />
                    <input type="text" name="message" placeholder="WRITE YOUR MESSAGE" className="border-b-2 border-[#131313] bg-transparent placeholder:text-[#131313] px-0 py-3 md:my-6 md:w-11/12" />
                    <div className="grid md:grid-cols-2 gap-4 md:w-11/12 mt-6">
                        <Button type="submit">GET A QUOTE</Button>
                        {/* ... your Contact Us button ... */}
                    </div>
                </form>
            </div>
        </div>
    );
}
