import React, { useState } from 'react';
export default function Map() {

    var List = {
        "SHOPPING": [
            "Inox- Nakshatra Mall",
            "Dadar Manish Market",
            "Vegetable & Grocery Market",
            "Star Mall"
        ],
        "THEATRES": [
            "Inox",
            "Dadar Plaza",
            "Chitra Cinema"
        ],
        "RELIGIOUS PLACES": [
            "Karsan Ladhu Nisar Hall",
            "Jain Mandir",
            "Portuguese Church",
            "Siddhivinayak Temple"
        ],
        "HOTELS": [
            "Ramee Guestline Hotel",
            "Hotel Avon Ruby",
            "Hotel Midtown Pritam",
            "Bawa Regency"
        ],
        "HOSPITAL": [
            "Pinto Hospital",
            "Gokhale Eve Hospital",
            "KEM Hospital",
            "Hinduja Hospital"
        ],
        "CORPORATE / BUSINESS HUBS": [
            "Sunshine Taser",
            "Naman Midtown",
            "Indiabulls Finance Centre",
            "The Ruby"
        ],
        "BANKS": [
            "State Bank of India",
            "Federal Bank",
            "Bank of Baroda",
            "Bank of India",
            "ICICI Bank",
            "Indian Bank",
            "TISB Bank",
            "Axis Bank"
        ],
        "EDUCATIONAL INSTITUTES": [
            "PIES Modern English School",
            "Dr. Antonio Da Silva School & College",
            "Our Lady of Salvation School",
            "Dadar Catering College",
            "Kohinoor Technical Institute",
            "National School of Banking",
            "Kirti College",
            "Pace Junior College",
            "Welingkar Institute",
            "Ruia College / Poddar College"
        ],
        "NEARBY LANDMARKS": [
            "Kabutar Khana",
            "Dadar Station",
            "Tilak Bridge",
            "Parel",
            "Shivaji Park",
            "Matunga Station - CR",
            "Bandra Worli Sea Link",
            "South Mumbai",
            "Bandra",
            "Airport",
            "Bandra Reclamation",
            "Taj Lands End"
        ]
    };
    
    const [expandedCategory, setExpandedCategory] = useState('');

    const handleCategoryToggle = (category) => {
        if (expandedCategory === category) {
            setExpandedCategory('');
        } else {
            setExpandedCategory(category);
        }
    };

    return (
        <div className="flex flex-col mt-2 md:px-10 lg:px-20">
            <h2 className="font-hl text-[18px] text-left md:text-3xl my-2 md:my-0 px-6 md:px-0">
                <img src="/images/logo.png" alt="logo" className="w-32 my-2" />
                PROMINENT LANDMARKS THAT<br />BRING YOUR WORLD CLOSER
            </h2>

            <div className="flex flex-col md:flex-row mt-4 md:mt-10">
                <img src="/images/map.png" alt="map" className="md:w-3/5 md:mr-8" />

                <div className="flex flex-col w-full px-6 md:px-0 mt-6 md:mt-0">
                    {Object.keys(List).map((category) => (
                        <div key={category} className="mb-2">
                            <button
                                className="border-t-2 border-[#D8B25D] px-6 py-3 flex justify-between items-center w-full"
                                onClick={() => handleCategoryToggle(category)}
                            >
                                <p className="font-hl uppercase md:text-[18px] text-[13px]">{category}</p>
                                <img src="/images/down.svg" alt="arrow" className="w-6" />
                            </button>
                            {expandedCategory === category && (
                                <ul className="mt-2">
                                    {List[category].map((landmark) => (
                                        <li key={landmark} className='font uppercase border-t-2 border-[#faecce] px-6 py-3 flex'>{landmark}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}